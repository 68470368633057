<template>
    <div class="paper-preview" v-if="list.length > 0">
        <div class="head">
            <div class="title">{{ paperName }}</div>
            <div class="total-score">( 总分100分 )</div>
        </div>
        <div class="main">
            <div class="type-item" v-for="(item, index) in packList" :key="index">
                <div class="type-box">
                    <span class="type-title"> {{ chinaNum[index] }}. {{ questionType[typeList[index]] }}</span>
                    <span class="info">( 共{{ item.length }}题，{{ item | typeScore }}分 )</span>
                </div>
                <div class="question-box">
                    <QuestionList :list="item" :isShowScore="true"></QuestionList>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import QuestionList from "./common/QuestionList.vue";
export default {
    name: "ArtificialPaperPreview",
    data() {
        return {
            questionType: ["单选题", "多选题", "判断题", "填空题", "问答题"],
            chinaNum: ["一", "二", "三", "四", "五"],
        };
    },
    components: {
        QuestionList,
    },
    props: {
        paperName: {
            type: String,
            default: "",
        },
        list: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    computed: {
        packList() {
            return this.list.filter((item, index) => item.length > 0);
        },
        typeList() {
            return this.packList.map((item) => item[0].type - 1);
        },
    },
    filters: {
        typeScore(typeList) {
            const score = typeList.reduce((sum, item) => sum * 1 + item.score * 1, 0);
            return (score + '').includes(".") ? score.toFixed(2) : score;
        },
    },
    methods: {},
};
</script>

<style scoped lang="scss">
.paper-preview {
    padding: 0 25px;
    color: #333;
    .head {
        font-size: 18px;
        text-align: center;
        margin-bottom: 23px;
        .total-score {
            font-size: 14px;
            color: #343441;
        }
    }
    .main {
        font-size: 13px;
        .type-item {
            .type-box {
                display: flex;
                align-items: center;
                margin-bottom: 11px;
                font-size: 15px;
                .info {
                    margin-left: 7px;
                    font-size: 13px;
                }
            }
        }
    }
}
</style>

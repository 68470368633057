<template>
    <div class="dialog">
        <el-dialog class="deep-color-dialog" :title="title" :visible.sync="isShowDialog" :width="width">
            <div class="main">
                <slot></slot>
                <div class="button-group">
                    <el-button v-if="isShowButton[0]" class="cancel" @click="cancel">取消</el-button>
                    <el-button v-if="isShowButton[1]" class="confirm blue-button" @click="confirm">确定</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "Dialog",
    data() {
        return {
            isShowDialog: false,
        };
    },
    props: {
        title: {
            type: String,
            default: "",
        },
        isShowButton: {
            type: Array,
            default() {
                return [true, true];
            },
        },
        cancelHandler: {
            type: Function,
            default() {
                return function () {
                    this.close();
                };
            },
        },
        confirmHandler: {
            type: Function,
            default() {
                return function () {};
            },
        },
        width: {
            type: String,
            default: "500px",
        },
        border: {
            type: Number,
            default: 10,
        },
    },
    components: {},
    methods: {
        open() {
            this.isShowDialog = true;
        },
        close() {
            this.isShowDialog = false;
        },
        confirm() {
            // this.close();
            this.confirmHandler();
        },
        cancel() {
            this.close();
            this.cancelHandler();
        },
    },
};
</script>

<style scoped lang="scss">
$theme-color: #1122d8;
.dialog {
    color: #333;
    position: absolute;
    .main {
        text-align: center;
        .button-group {
            color: #999999;
            ::v-deep .el-button {
                width: 80px;
                height: 40px;
            }
        }
    }
}
::v-deep .el-dialog {
    border-radius: 10px;
    overflow: hidden;
}
::v-deep .el-dialog__header {
    height: 48px;
    padding: 0 24px;
    line-height: 48px;
    background-color: $theme-color;
}
::v-deep .el-dialog__headerbtn .el-icon {
    color: #fff;
}
::v-deep .el-dialog__headerbtn {
    top: 15px;
}
::v-deep .el-dialog__title,
::v-deep .el-dialog__body {
    font-size: 15px !important;
    color: #333;
}
::v-deep .el-dialog__body {
    padding-top: 0;
}
::v-deep .el-dialog__title {
    color: #fff;
}
::v-deep .el-button {
    padding: 10px 7px;
}
//button
::v-deep .blue-button {
    background-color: $theme-color;
    border-color: $theme-color;
    color: #fff;
}
::v-deep .cancel:hover {
    transition: all 0.3s;
    color: $theme-color;
    background-color: #ecf0ff;
    border: 1px solid #b7cadf;
}
</style>

<template>
    <div class="artificial-paper" v-loading="loading" element-loading-text="加载中..." element-loading-spinner="el-icon-loading">
        <ArtificialPaperHead :currentStep.sync="currentStepIndex" @stepIndexChange="stepIndexChange" />
        <div class="main">
            <div class="left-set-score" v-if="currentStepIndex == 1">
                <ArtificialPaperSetScore
                    :questionList="selectQuestionList"
                    :questionLibraryList="questionLibraryList"
                    :setScore="setScore"
                    :paperName.sync="paperSendParams.paper_name"
                    :categoryId.sync="paperSendParams.category_id"
                    :totalScore="selectListTotalScore"
                />
            </div>
            <div class="right-question">
                <el-scrollbar style="height: 100%">
                    <!-- <div class="scroll-content" v-infinite-scroll="load"> -->
                    <div class="scroll-content">
                        <ArtificialPaperFilter
                            v-if="currentStepIndex == 0 || currentStepIndex == 1"
                            :questionTypeList="questionTypeList"
                            :questionLibraryList="questionLibraryList"
                            :questionTypeIndex.sync="getListParams.type"
                            @questionLibraryValue="questionLibraryValue"
                            :questionLibraryValue="getListParams.category_id"
                            :stepIndex="currentStepIndex"
                            :isShowRes.sync="isShowRes"
                            :searchVal.sync="getListParams.like"
                            :key="currentStepIndex"
                        />
                        <ArtificialPaperOperate
                            v-if="currentStepIndex == 0"
                            :searchVal.sync="getListParams.like"
                            :isShowRes.sync="isShowRes"
                            :toggleAllQuestionExistState="toggleAllQuestionExistState"
                            :totalQuestionCount="totalQuestionCount"
                            :selectList="selectQuestionList"
                            :deleteTypeQuestion="deleteTypeQuestion"
                        />
                        <ArtificialPaperList
                            v-if="currentStepIndex == 0 || currentStepIndex == 1"
                            :list="packList"
                            :isShowRes="isShowRes"
                            @toggleQuestionExistState="toggleQuestionExistState"
                            :stepIndex.sync="currentStepIndex"
                            :questionType="getListParams.type"
                            :exchangeQuestion="exchangeQuestion"
                        />
                        <ArtificialPaperPreview
                            v-if="currentStepIndex == 2"
                            :paperName="paperSendParams.paper_name"
                            :list="packList"
                        ></ArtificialPaperPreview>
                        <div class="paging-box" v-if="currentStepIndex == 0 && questionList.length > 0">
                            <el-pagination
                                :current-page.sync="getListParams.page"
                                :page-size="getListParams.pageSize"
                                layout="prev, pager, next, jumper"
                                :total="totalQuestionCount"
                            >
                            </el-pagination>
                        </div>
                        <PaperCreateSucceed v-if="currentStepIndex == 3"></PaperCreateSucceed>
                    </div>
                </el-scrollbar>
            </div>
        </div>
    </div>
</template>

<script>
import ArtificialPaperHead from "components/admin/paperManage/ArtificialPaperHead";
import ArtificialPaperFilter from "components/admin/paperManage/ArtificialPaperFilter";
import ArtificialPaperOperate from "components/admin/paperManage/ArtificialPaperOperate";
import ArtificialPaperList from "components/admin/paperManage/ArtificialPaperList";
import ArtificialPaperSetScore from "components/admin/paperManage/ArtificialPaperSetScore";
import ArtificialPaperPreview from "components/admin/paperManage/ArtificialPaperPreview";

import PaperCreateSucceed from "components/admin/paperManage/common/PaperCreateSucceed";

import {
    theoryTopicList,
    theoryPaperQuestionList,
    theoryLibSelect,
    theoryPaperCreate,
    theoryGetArtificialPaperDetail,
} from "@/utils/apis";
import { setTimeout } from "timers";

export default {
    name: "ArtificialPaper",
    data() {
        return {
            loading: false,
            selectQuestionList: [[], [], [], [], []],
            //请求题目参数
            getListParams: {
                paging: 1,
                page: 1,
                pageSize: 10,
                category_id: "",
                type: 0,
                like: "",
            },
            /**新增、编辑组卷 */
            paperSendParams: {
                paper_name: "",
                category_id: [],
                choose_paper_data: [],
                paper_id: "", //编辑组卷传
            },
            //ArtificialPaperHead
            currentStepIndex: 0, //当前步数下标
            //ArtificialPaperFilter
            questionTypeList: [], //题型数组
            questionLibraryList: [], //题库数组
            //ArtificialPaperOperate
            isShowRes: false, //是否显示答案button
            totalQuestionCount: 0,
            //ArtificialPaperList
            questionList: [], //题目列表
        };
    },
    components: {
        ArtificialPaperHead,
        ArtificialPaperFilter,
        ArtificialPaperOperate,
        ArtificialPaperList,
        ArtificialPaperSetScore,
        ArtificialPaperPreview,
        PaperCreateSucceed,
    },
    watch: {
        "getListParams.type"() {
            this.getListParams.page = 1;
            this.getQuestionList();
        },
        "getListParams.category_id"() {
            this.getQuestionList();
        },
        "getListParams.like"() {
            this.getQuestionList();
        },
        "getListParams.page"() {
            this.getQuestionList();
        },
        currentStepIndex(newVal, oldVal) {
            if (oldVal == 0 && newVal == 1) {
                this.getListParams.type = 0;
            }else if((oldVal == 1 && newVal == 0)|| (oldVal == 2 && newVal == 1)) {
                this.getListParams.like = "";
                this.getQuestionList();
            }
        },
        allSelectQuestion: {
            handler(newVal) {
                if (newVal.length <= 0 && this.currentStepIndex == 1) {
                    this.$message.warning("请重新选择题目");
                    setTimeout(() => {
                        this.currentStepIndex = 0;
                    }, 500);
                }
            },
            deep: true,
        },
    },
    mounted() {
        let id = this.$route.query.id;
        if (id) {
            this.paperSendParams.paper_id = id;
        }
        this.init();
    },
    computed: {
        /**扁平化选中的题目列表 */
        allSelectQuestion() {
            return this.selectQuestionList.flat().map((item, index) => ({ ...item, sort: index }));
        },
        /**选中题目的总分 */
        selectListTotalScore() {
            return (this.allSelectQuestion.reduce((sum, item) => sum * 1 + item.score * 1, 0)).toFixed(2);
        },
        /**为最终显示在页面上的列表做筛选 */
        packList() {
            let { questionList, currentStepIndex, getListParams, allSelectQuestion, selectQuestionList } = this;
            let list = [];
            if (currentStepIndex == 0) {
                list = questionList.map((item) => ({
                    ...item,
                    isExistList: allSelectQuestion.some((selectItem) => selectItem.theory_topic_id == item.theory_topic_id),
                }));
            } else if (currentStepIndex == 1) {
                let search = getListParams.like;
                let type = getListParams.type;
                list = type == 0 ? allSelectQuestion : selectQuestionList[type - 1];
                list = search ? list.filter((item) => item.title.search(getListParams.like) != -1) : list;
            } else if (currentStepIndex == 2) {
                list = selectQuestionList;
            }
            return list;
        },
    },
    methods: {
        questionLibraryValue(val) {
            this.getListParams.category_id = val
        },
        /**初始化数据 */
        async init() {
            this.paperSendParams.paper_id && (await this.editPaper());
            this.getQuestionList();
            this.getQuestionTypeList();
            this.getQuestionLibrary();
        },
        /**获取题目列表 */
        async getQuestionList(isAppend = false) {
            if (this.currentStepIndex != 0 || this.loading == true) {
                return false;
            }
            let { getListParams } = this;
            this.loading = true;
            this.questionList = [];
            let res = await theoryPaperQuestionList(getListParams);
            if (res.code == 200) {
                let list = res.data.list;
                list.forEach(this.handleListData);
                this.totalQuestionCount = res.data.total;
                this.questionList = list;
                this.loading = false;
            } else {
                this.$message.warning(res.msg);
            }
        },
        /**获取列表设置额外属性和解析 */
        handleListData(data) {
            //答案为空不显示答案
            let getResIsEmpty = (item) => {
                return (data.type == 5 && new Boolean(item.analysis)) || data.answer.length > 0 ? true : false;
            };
            data.options = JSON.parse(data.options);
            data.answer = JSON.parse(data.answer);
            data.isShowRes = getResIsEmpty(data);
            data.score = data.score ? data.score : "";
        },
        /**获取题型列表 */
        async getQuestionTypeList() {
            let { getListParams } = this;
            let res = await theoryTopicList(getListParams);
            if (res.code == 200) {
                this.questionTypeList = res.data.question_type;
                this.questionTypeList.unshift({
                    type_id: 0,
                    type_name: "全部",
                });
            } else {
                this.$message.warning(res.msg);
            }
        },
        /**获取题库列表 */
        async getQuestionLibrary() {
            let res = await theoryLibSelect();
            if (res.code == 200) {
                this.questionLibraryList = res.data.map((item, index) => {
                    let packData = (obj) => ({
                        label: obj.name,
                        value: obj.id,
                    });
                    let temp = packData(item);
                    if (item.children.length > 0) {
                        temp.children = item.children.map(packData);
                    }
                    return temp;
                });
            } else {
                this.$message.warning(res.msg);
            }
        },
        /**修改题目是否被选中的状态 */
        toggleQuestionExistState({ question, state }) {
            let { selectQuestionList: list, getListParams } = this;
            let type = question.type - 1;
            if (state) {
                list[type].push(question);
            } else {
                let index = list[type].findIndex((item) => item.theory_topic_id == question.theory_topic_id);
                index != -1 && list[type].splice(index, 1);
            }
        },
        /**修改所有题目的选中状态 */
        toggleAllQuestionExistState(state) {
            let { selectQuestionList: list, questionList, allSelectQuestion } = this;
            if (state) {
                questionList.forEach((item) => {
                    let type = item.type - 1;
                    if (!allSelectQuestion.some((selectItem) => selectItem.theory_topic_id == item.theory_topic_id)) {
                        list[type].push(item);
                    }
                });
            } else {
                list.forEach((item) => item.splice(0, item.length));
            }
        },
        /**删除某个类型的题目 */
        deleteTypeQuestion(type) {
            let list = this.selectQuestionList[type];
            list.splice(0, list.length);
        },
        /**设置题目的分数 */
        setScore(score, question) {
            question.score = score;
        },
        /**题目拖拽处理 */
        exchangeQuestion({ start, end }) {
            let { selectQuestionList, allSelectQuestion } = this;
            let type = this.getListParams.type - 1;
            let list = selectQuestionList[type];
            let startIndex = list.findIndex((item, index) => item.theory_topic_id == start);
            let endIndex = list.findIndex((item, index) => item.theory_topic_id == end);
            let temp = list[startIndex];
            this.$set(list, startIndex, list[endIndex]);
            this.$set(list, endIndex, temp);
        },
        /**每下一步 */
        async stepIndexChange(value) {
            let errMsg = "";
            if (value == 1 && this.allSelectQuestion.length <= 0) {
                errMsg = "没有选择题目";
            } else if (value == 2) {
                let conditions = [
                    !this.paperSendParams.paper_name,
                    this.paperSendParams.paper_name.length < 4,
                    this.paperSendParams.paper_name.length > 25,
                    this.paperSendParams.category_id.length <= 0,
                    this.allSelectQuestion.some((item) => !item.score),
                    this.selectListTotalScore * 1 !== 100,
                ];
                let msgs = [
                    "未填写试卷名称",
                    "试卷名称长度不能小于4",
                    "试卷名称长度不能大于25",
                    "未选择试卷分类",
                    "有题目未设置分数",
                    "试卷总分只能是100分",
                ];
                let errIndex = conditions.findIndex((item) => item);
                errMsg = errIndex == -1 ? "" : msgs[errIndex];
            } else if (value == 3) {
                let isCreateSucceed = await this.createPaper();
                errMsg = isCreateSucceed ? "" : "创建失败";
            }
            errMsg && value != 3 && this.$message.error(errMsg);
            if (!errMsg) this.currentStepIndex = value;
        },
        /**创建试卷 */
        async createPaper() {
            this.loading = true;    
            return new Promise(async (resolve, reject) => {
                let { paperSendParams } = this;
                let arr = this.allSelectQuestion;
                let paperParams = arr.map((item, index) => ({
                    theory_topic_id: item.theory_topic_id,
                    score: item.score,
                    sort: item.sort,
                }));
                paperSendParams.choose_paper_data = JSON.stringify(paperParams);
                paperSendParams.category_id = paperSendParams.category_id[1];
                let res = await theoryPaperCreate(paperSendParams);
                if (res.code == 200) {
                    this.$message.success(res.msg);
                    resolve(true);
                } else {
                    this.$message.warning(res.msg);
                    resolve(false);
                }
                this.loading = false;
            });
        },
        /**由编辑试卷进入 */
        async editPaper() {
            let { paperSendParams, questionList, selectQuestionList } = this;
            let res = await theoryGetArtificialPaperDetail({ paper_id: paperSendParams.paper_id });
            if (res.code == 200) {
                res.data.topic_list.forEach((item) => {
                    let type = item.type - 1;
                    this.handleListData(item);
                    this.selectQuestionList[type].push(item);
                    this.paperSendParams.paper_name = res.data.paper_name;
                    this.paperSendParams.category_id = res.data.paper_category_id_arr;
                });
            } else {
                this.$message.error(res.msg);
            }
        },
        /**触底 */
        load() {
            if (this.currentStepIndex == 0) {
                this.getQuestionList(true);
            }
        },
    },
};
</script>

<style scoped lang="scss">
::v-deep ul,
li {
    padding: 0;
    margin: 0;
    list-style: none;
}
.artificial-paper {
    display: flex;
    flex-direction: column;
    padding: 30px 0;
    height: 100%;
    .main {
        flex: 1;
        display: flex;
        height: 100%;
        overflow: hidden;
        .left-set-score {
            width: 350px;
            height: 93%;
        }
        .right-question {
            flex: 1;
            display: flex;
            flex-direction: column;
            // overflow: scroll;
            overflow: hidden;
            height: 100%;
        }
        .scroll-content {
            height: 100%;
            .paging-box {
                padding-bottom: 20px;
                margin-bottom: 20px;
                text-align: center;
            }
        }
    }
}
// ::v-deep .el-scrollbar__view {
//     height: 100%;
// }
</style>
